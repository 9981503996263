.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 90%;
  left: 0px;
  background-color: #00291b;
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.menu-item:hover .submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.submenu li {
  padding: 10px 20px;
  text-align: left !important;
  transition: all 0.3s ease;
}

.submenu li:hover {
  background-color: #23b49d;
  color: white;
}

.submenu li a {
  text-align: left !important
  ;
  text-decoration: none;
  color: white;
}
